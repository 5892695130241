import React from 'react';
import PrimaryButton from './PrimaryButton';
import CoverImage from '../assets/cover-image.png';
import { useMediaQuery } from 'react-responsive';
import logo from '../assets/logo/logoDark.svg';

const Cover = () => {

    const isMobile = useMediaQuery({ maxWidth: 768 });
    const isSmall = useMediaQuery({ maxHeight: 677 });

    const containerStyle = {
        display: 'flex',
        height: isMobile ? isSmall? '65vh' :'55vh' : '75vh',
        color: 'white',
    };

    const contentContainerStyle = {
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: isMobile ? 'center': 'space-around',
        justifyContent: 'center',
        paddingLeft: isMobile ? '0rem': '9rem',
        margin: isSmall ? '3rem 0rem': '0rem',
    };

    const brandContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

    }

    const headerStyle = {
        height: "26vh",
    };

    const descriptionStyle = {
        fontSize: isSmall ? '1rem': '1.3rem',
        marginBottom: '1rem',
        textAlign: 'center',
        marginTop: '0.5rem',
    };

    const buttonContainerStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const imgStyle = {
        flex: 1,
        width: '33vw',
        height: 'auto',
        objectFit: 'cover',
        paddingRight: '10rem',
    };

    const buttonStyle = {
        fontSize: '1rem',
        padding: '10px 20px',
        borderRadius: '5px',
        cursor: 'pointer',
    };

    return (
        <div style={isMobile ? { ...containerStyle, flexDirection: 'column' } : containerStyle}>
            <div style={contentContainerStyle}>
                <div style={brandContainerStyle} >
                    <img style={headerStyle} src={logo}/>
                    <p style={descriptionStyle}>Revolutionizing Music Production <br/> Transforming The Path to Success</p>
                </div>
                <div style={isMobile ? { ...buttonContainerStyle, flexDirection: 'column' } : buttonContainerStyle}>
                    <PrimaryButton size={1.3} style={buttonStyle}>
                       Learn More
                    </PrimaryButton>
                    <PrimaryButton size={1.3} border={"#FFA500"} color={'none'} style={buttonStyle}>
                        Predict Hit 
                    </PrimaryButton>
                </div>
            </div>
            {isMobile ? null :
                <div style={{display: 'flex', alignItems: "center", justifyContent: "center"}}>
                    <img src={CoverImage} alt="Cover" style={imgStyle} />
                </div>
            }
        </div>
    );
};

export default Cover;
