import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './pages/App';
import About from './pages/About';
import Contact from './pages/Contact';


const AppRouter = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/about" element={<About />} />    
      <Route path="/contact" element={<Contact />} />    

      <Route path="*" element={<div>Not Found: 404</div>} />
    </Routes>
  </BrowserRouter>
);

export { AppRouter };