import { useState } from 'react';
import { ProviderThemes } from '../styles/Provider';
import styled from "@emotion/styled"
import '../styles/App.css';
import NavBarComponent from '../components/Navbar';
import ImageGridComponent from '../components/HomeItem';
import Cover from '../components/Cover';

const Title = styled.h1`
  font-size: 1.5em;
  color: ${(props) => props.theme.primary.text};
`

function App() {
  const navItems = [
    { link: '/', text: 'Home' },
    { link: '/about', text: 'About' },
    { link: '/contact', text: 'Contact' },
  ];

  const mainStyle = {
    background: 'linear-gradient(360deg, #16182c 0%, #16182c 100%)',
    fontFamily: 'Inclusive Sans',
  };
  return (
    <div style={mainStyle}>
      <NavBarComponent items={navItems} height={5}  />
      <Cover/>
      <ImageGridComponent size={35} items={[
        { imageUrl: "https://picsum.photos/id/237/200/300", altText: "Description of image 1", title:"Lorem ipsum", text: "Lorem ipsum dolor sit amet. Et dolores magnam est eius ratione eum nihil debitis. Et officiis quae eum totam aspernatur sit sunt voluptatem est ipsa dolorem in incidunt dolores. Et aliquam necessitatibus qui dolor aperiam sed galisum velit aut dolores similique." },
        { imageUrl: "https://picsum.photos/200/300", altText: "Description of image 2", title:"Lorem ipsum", text: "Lorem ipsum dolor sit amet. Et dolores magnam est eius ratione eum nihil debitis. Et officiis quae eum totam aspernatur sit sunt voluptatem est ipsa dolorem in incidunt dolores. Et aliquam necessitatibus qui dolor aperiam sed galisum velit aut dolores similique." },
        { imageUrl: "https://picsum.photos/200/200", altText: "Description of image 2", title:"Lorem ipsum", text: "Lorem ipsum dolor sit amet. Et dolores magnam est eius ratione eum nihil debitis. Et officiis quae eum totam aspernatur sit sunt voluptatem est ipsa dolorem in incidunt dolores. Et aliquam necessitatibus qui dolor aperiam sed galisum velit aut dolores similique." },
      ]} />
    </div>
  );
}

export default App;
