import React, { useState } from 'react';
import logo from '../assets/logo/dolphinDark.svg';
import { MdOutlineMenu, MdClose } from 'react-icons/md';
import { IconContext } from "react-icons";
import PrimaryButton from './PrimaryButton';
import { useMediaQuery } from 'react-responsive';

const NavBarComponent = ({ items, height }) => {

  const [isOpen, setIsOpen] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const navStyle = {
    position: 'relative',
    display: 'flex',
    height: `${height}rem`,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem 2rem',
    background: 'linear-gradient(90deg, #1F2136 0%, #20263B 50%, #1F2136 100%)',
    color: '#FFFFFF',
    fontSize: `${height/3.5}rem`, 
    zIndex: 102,
  };
  
  const listItemStyle = {
    marginRight: `${height/3.8}rem`
  };
  
  const linkStyle = {
    color: '#FFFFFF',
    textDecoration: 'none',
  };

  const sidebarStyle = {
    position: 'fixed',
    top: 0,
    transform: `translateY(${isOpen ? 0 : '-100%'})`,
    width: '100%',
    height: '100vh',
    backgroundImage: 'linear-gradient(90deg, #1F2136 0%, #20263B 50%, #1F2136 100%)',
    transition: 'transform 0.3s ease-in-out',
    fontSize: `${height / 2}rem`,
    zIndex: 101, 
  };
  

  const menuButtonCloseStyle = {
    display: 'none',
    margin: `${height/3.5}rem`,
    zIndex: 2
  };

  const sidebarListStyle = {
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    padding: '1rem 2rem',
    height: `calc(100vh - ${height}rem)`,
    alignItems: 'center',
    paddingTop: `${height}rem`,
    opacity: !isOpen ? "0" : "1",
    transition: "all .1s",
    visibility: !isOpen ? "hidden" : "visible",
    zIndex: 100
  }

  return (
    <>
      <div style={navStyle}>
        
        <img src={logo} height="70%" alt="Logo" />
        { !isMobile ?
        <ul style={{ display: 'flex', listStyle: 'none', padding: 0 }}>
          {items.map((item, index) => (
            <PrimaryButton
              style={listItemStyle}
              key={index}
              className='desktop-nav-item'
              href={item.link}
              size={height / 4.2}
            >
              {item.text}
            </PrimaryButton>
          ))}
        </ul>
        :
        <IconContext.Provider value={{ color: '#FFFFFF' }}>
          <div
            onClick={() => setIsOpen(!isOpen)}
            style={menuButtonCloseStyle}
            className="mobile-menu-button"
          >
            {isOpen ? (
              <MdClose size={`${height / 1.5}rem`} />
            ) : (
              <MdOutlineMenu size={`${height / 1.5}rem`} />
            )}
          </div>
        </IconContext.Provider>
        }
      </div>
          <div className="sidebar" style={sidebarStyle}>
            <ul style={sidebarListStyle}>
              {items.map((item, index) => (
                <li key={index} style={{margin: `${height/7 }rem`}}>
                  <a href={item.link} style={linkStyle}>{item.text}</a>
                </li>
              ))}
            </ul>
          </div>
      <style jsx>{`
        @media (max-width: 768px) {
          nav {
            position: fixed !important;
            z-index: 100;
            width: calc(100vw - 4rem);
          }

          .desktop-nav-item {
            display: none !important;
          }
          .mobile-menu-button {
            display: inline-block !important;
          }
        }
      `}</style>
    </>
  );
};

export default NavBarComponent;
