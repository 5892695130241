import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const People = ({ items, size, title, hover }) => {
  const isMobile = useMediaQuery({ maxWidth: 760 });

  const [hoveredIndex, setHoveredIndex] = useState(null);

  hover = hover === undefined ? true : hover;

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflowY: 'auto',
    color: '#F0F0F0',
    fontFamily: 'Inclusive Sans',
    zIndex: 0,
  };

  const itemStyle = {
    display: 'flex',
    flexDirection: isMobile ? 'column' :'row',
    alignItems: 'center',
    width: isMobile ? '80%' : '75%',
    marginBottom: `${size / 10}rem`,
    background: 'linear-gradient(45deg, #251E42 0%, #1E2A42  100%)',
    padding: '1rem',
    borderRadius: '2rem',
    transition: 'transform 0.3s ease',
    cursor: (!isMobile && hover) ? 'pointer' : 'default',
    zIndex: 0
  };

  const textStyle = {
    fontSize:  isMobile ? `${size / 25}rem` : `${size / 25}rem`,
  };

  const imageStyle = {
    width: isMobile ? `${size * 0.5}rem` : `${size * 0.5}rem`,
    height: isMobile ? `${size * 0.5}rem` : `${size * 0.5}rem`,
    margin: '1rem',
    borderRadius: '100rem',
  };

  const textBox = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: isMobile ? 'center' : 'flex-start',
    justifyContent: 'center',
    flex: 1,
    marginLeft: '1rem',
    marginRight: '0.5rem',
    marginBottom: isMobile ? `${size / 0}rem` : `0rem`,
    };

  const handleMouseEnter = (index) => {
    console.log(hover)
    if (!isMobile && hover) {
      setHoveredIndex(index);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile && hover) {
      setHoveredIndex(null);
    }
  };

  const sortedItems = [...items].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div style={containerStyle}>
      <div style={{display: "flex",flex: 1, width: "90vw", alignItems: "flex-start"}}>
        <h1 style={{margin: "1.5rem", color: "#FFFFFF"}}>{title}</h1>
      </div>
      {sortedItems.map((item, index) => (
        <div 
          key={index}
          style={{ 
            ...itemStyle,
            ...(index === 0 ? { marginTop: isMobile ? `${size / 20}rem` : `${size / 20}rem` } : { marginTop: `${size / 100}rem` }),
            transform: !isMobile && hoveredIndex === index ? 'scale(1.1)' : 'scale(1)'
          }}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          <img
            src={item.imageUrl}
            alt={item.altText}
            style={{
              ...imageStyle,
            }}
          />
          <div style={textBox}>
            <h1 style={{ fontSize: `${size / 14}rem`, margin: "0rem ", textAlign: isMobile ? 'center' : 'flex-start' }}>{item.name}</h1>
            <p style={textStyle}>{item.label}</p>
            {item.link ? item.link.map((item, index) => (
              <>
                <a href={item.url} style={{color: "#FFFFFF",marginBottom: isMobile ? `${size / 30}rem` : `0rem`,}}>{item.text ? item.text : item.url}</a>
              </>
            ))
            : null
            }
          </div>
        </div> 
      ))}
    </div>
  );
};

export default People;