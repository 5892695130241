import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import PrimaryButton from './PrimaryButton';

const ContactBox = ({ onSend }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [subjectError, setSubjectError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 760 });
    const maxNameLength = 80;
    const maxSubjectLength = 100;
    const maxMessageLength = 10000;

    const containerStyle = {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        color: 'white',
        flexDirection: 'column',
    };

    const headerStyle = {
        fontSize: '3rem',
        fontWeight: 'bold',
        marginBottom: '0rem',
    };

    const labelStyle = {
        fontSize: '1.3rem',
        margin: '0.5rem',
    };

    const inputStyle = {
        padding: '0.5rem',
        fontSize: '1.3rem',
        borderRadius: '0.5rem',
        border: '1px solid #ccc',
    };

    const counterStyle = {
        fontSize: '0.8rem',
        color: 'gray',
        paddingBottom: '0.3rem',
        alignSelf: 'flex-end',
    };

    const errorBorderStyle = {
        borderColor: 'red',
    };

    const itemsContainerStyle = { 
        display: 'flex', 
        flex: 1, 
        flexDirection: 'column', 
        width:  isMobile ? '75vw' : '65vw', 
    }

    const handleSubmit = () => {
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        
        if (!emailRegex.test(email)) {
            setEmailError(true);
            return;
        }

        if (name.trim() === '') {
            setNameError(true);
            return;
        }

        if (subject.trim() === '') {
            setSubjectError(true);
            return;
        }

        if (message.trim() === '') {
            setMessageError(true);
            return;
        }

        onSend(name, email, subject, message);

        setName('');
        setEmail('');
        setSubject('');
        setMessage('');
        setNameError(false);
        setEmailError(false);
        setSubjectError(false);
        setMessageError(false);
    };

    const handleNameChange = (e) => {
        const newName = e.target.value.slice(0, maxNameLength);
        setName(newName);
        setNameError(false);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError(false);
    };

    const handleSubjectChange = (e) => {
        const newSubject = e.target.value.slice(0, maxSubjectLength);
        setSubject(newSubject);
        setSubjectError(false);
    };

    const handleMessageChange = (e) => {
        const newMessage = e.target.value.slice(0, maxMessageLength);
        setMessage(newMessage);
        setMessageError(false);
    };

    return (
        <div style={containerStyle}>
            <h1 style={headerStyle}>Contact Us</h1>
            <div style={itemsContainerStyle}>
                <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', margin: '0.5rem 0' }}>
                    <div style={{ display: 'flex', width: '100%', flex: 1, flexDirection: 'column', marginRight:  isMobile ? "0rem": '1rem' }}>
                        <label htmlFor="name" style={labelStyle}>
                            Name:
                        </label>
                        <input
                            type="text"
                            id="name"
                            style={{
                                ...inputStyle,
                                width: '100%',
                                margin: '0.5rem 0',
                                ...(nameError && errorBorderStyle),
                            }}
                            placeholder="Your Name"
                            value={name}
                            onChange={handleNameChange}
                        />
                        <span style={counterStyle}>{name.length}/{maxNameLength}</span>
                    </div>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginLeft: isMobile ? "0rem": '1rem' }}>
                        <label htmlFor="email" style={{...labelStyle, margin: isMobile ? "0rem !important" : "0.5rem"}}>
                            Email:
                        </label>
                        <input
                            type="email"
                            id="email"
                            style={{
                                ...inputStyle,
                                width: '100%',
                                margin: '0.5rem 0',
                                ...(emailError && errorBorderStyle),
                            }}
                            placeholder="Your Email"
                            value={email}
                            onChange={handleEmailChange}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: "0rem"}}>
                    <label htmlFor="subject" style={labelStyle}>
                        Subject:
                    </label>
                    <input
                        type="text"
                        id="subject"
                        style={{
                            ...inputStyle,
                            width: '100%',
                            margin: '0.5rem 0',
                            ...(subjectError && errorBorderStyle),
                        }}
                        placeholder="Subject"
                        value={subject}
                        onChange={handleSubjectChange}
                    />
                    <span style={counterStyle}>{subject.length}/{maxSubjectLength}</span>
                </div>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: '0.5rem !important' }}>
                    <label htmlFor="message" style={labelStyle}>
                        Message:
                    </label>
                    <textarea
                        id="message"
                        style={{
                            ...inputStyle,
                            resize: 'none',
                            width: '100%',
                            margin: '0.5rem 0',
                            ...(messageError && errorBorderStyle),
                        }}
                        rows={6}
                        placeholder="Write your message here..."
                        value={message}
                        onChange={handleMessageChange}
                    />
                    <span style={counterStyle}>{message.length}/{maxMessageLength}</span>
                </div>
            </div>
            <PrimaryButton onClick={handleSubmit} size={1.5} style={{ marginTop: '1.5rem', paddingLeft: '1rem', }}>
                Submit
            </PrimaryButton>
        </div>
    );
};

export default ContactBox;
