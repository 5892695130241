import formData from 'form-data';
import axios from 'axios';
import Mailgun from 'mailgun.js';

const mailgun = new Mailgun(formData);

async function getGeolocationData() {
    try {
        const response = await axios.get('https://ipinfo.io/json', {
            headers: {
                'Authorization': 'Bearer 876bf2700a0d36',
            },
        });

        const data = response.data;
        return {
            ip: data.ip,
            hostname: data.hostname,
            city: data.city,
            region: data.region,
            country: data.country,
            loc: data.loc,
            org: data.org,
            postal: data.postal,
            timezone: data.timezone,
        };
    } catch (error) {
        console.error(error);
        return {};
    }
}

function formatName(name) {
    return name
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

async function SendMail(Name, from, subject, text) {
    const mailgunApiKey = process.env.MAILGUN_API_KEY || '52122c536b518415b7fb3b8b98ea89d5-db137ccd-22b6e9fb';
    const mg = mailgun.client({ username: 'api', key: mailgunApiKey });
    const metadata = await getGeolocationData();

    const formattedName = formatName(Name);
    const lowercaseFrom = from.toLowerCase();

    mg.messages.create('mail.aiudio.com.br', {
        from: `${formattedName} <postmaster@mail.aiudio.com.br>`,
        to: ['marcoulakis@aiudio.com.br', 'sophia@aiudio.com.br'],
        subject: subject,
        text: text,
        html: `
            <h1>${subject}</h1>
            <p>${text}</p>
            <div>
                <p>--------------------------------------------------------------------------</p>
                <p>${formattedName} email: ${lowercaseFrom}</p>
                <p>${formattedName} IP Address: ${metadata.ip}</p>
                <p>${formattedName} Hostname: ${metadata.hostname}</p>
                <p>${formattedName} City: ${metadata.city}</p>
                <p>${formattedName} Region: ${metadata.region}</p>
                <p>${formattedName} Country: ${metadata.country}</p>
                <p>${formattedName} Location: ${metadata.loc}</p>
                <p>${formattedName} Organization: ${metadata.org}</p>
                <p>${formattedName} Postal Code: ${metadata.postal}</p>
                <p>${formattedName} Timezone: ${metadata.timezone}</p>
            </div>
        `,
    })
        .then((msg) => console.log(msg))
        .catch((err) => console.error(err));
}

export default SendMail;
