import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const ImageListComponent = ({ items, size }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflowY: 'auto',
    color: '#F0F0F0',
    fontFamily: 'Inclusive Sans',
    zIndex: 0
  };

  const itemStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    width: isMobile ? '80%' : '60%',
    marginBottom: `${size / 7.5}rem`,
    background: 'linear-gradient(45deg, #251E42 0%, #1E2A42  100%)',
    padding: '1rem',
    borderRadius: '2rem',
    transition: 'transform 0.3s ease',
    cursor: 'pointer',
    zIndex: 0
  };

  const textStyle = {
    fontSize: `${size / 25}rem`,
    marginLeft: `${size / 15}rem`,
  };
  const imageStyle = {
    width: `${size * 0.5}rem`,
    margin: '1rem',
    borderRadius: '0.5rem',
  };

  const mobileItemStyle = {
    flexDirection: 'column',
    alignItems: 'center',
  };

  const mobileImageStyle = {
    width: '75%',
  };

  const textBox = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: isMobile ? 'center' : 'flex-start',
    justifyContent: 'center',
    width: isMobile ? '100%' : '40%',
  };

  const handleMouseEnter = (index) => {
    if (!isMobile) {
      setHoveredIndex(index);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setHoveredIndex(null);
    }
  };

  return (
    <div style={containerStyle}>
      {items.map((item, index) => (
        <div
          key={index}
          style={{
            ...itemStyle,
            ...(isMobile ? mobileItemStyle : index % 2 === 0 ? { flexDirection: 'row', marginRight: `${size / 1.6}rem` } : { flexDirection: 'row-reverse', marginLeft: `${size / 1.6}rem` }),
            ...(index === 0 ? { marginTop: isMobile ? `${size / 25}rem` : `${size / 25}rem` } : { marginTop: `${size / 100}rem` }),
            transform: !isMobile && hoveredIndex === index ? 'scale(1.1)' : 'scale(1)'
          }}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          <img
            src={item.imageUrl}
            alt={item.altText}
            style={{
              ...imageStyle,
              ...(isMobile && mobileImageStyle ? mobileImageStyle : index % 2 === 0 ? { marginRight: `${size / 10}rem` } : { marginLeft: `${size / 5}rem` }),
            }}
          />
          <div style={textBox}>
            <h1 style={{ fontSize: `${size / 20}rem` }}>{item.title}</h1>
            <p style={textStyle}>{item.text}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ImageListComponent;
