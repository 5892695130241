import React from 'react';
import { useMediaQuery } from 'react-responsive';

const Article = ({title, text, imageUrl, altText}) => {

    const isMobile = useMediaQuery({ maxWidth: 768 });

    const containerStyle = {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        color: 'white',
        flexDirection: "column"
    };

    const headerStyle = {
        fontSize: isMobile ? '6vw':'3rem',
        fontWeight: 'bold',
        marginBottom: '0rem',
        marginTop: '1.5rem',
    };

    const descriptionStyle = {
        fontSize: isMobile ? '1rem' : '1.3rem',
        margin: isMobile ?  "1rem 2.5rem" : "1rem 15rem",
        textAlign: 'center',
        marginTop: '0.5rem',
    };


    const imgStyle = {
        height: 'auto',
        width: isMobile ? '75vw' : '45vw',
        objectFit: 'cover',
        margin:  isMobile ?  '1rem' : '2rem',
        borderRadius: '1rem',
    };


    return (
        <div style={containerStyle}>
            <h1 style={headerStyle}>{title}</h1>
            <img src={imageUrl} alt={altText} style={imgStyle} />
            <p style={descriptionStyle}>{text}</p>
        </div>
    );
};

export default Article;
