import '../styles/App.css';
import NavBarComponent from '../components/Navbar';
import People from "../components/People";
import Article from '../components/Article';
import studioPic from '../assets/studioPic.jpg';

const textOfArticle = "Our mission is led by two individuals, Sophia and Marina, who possess a wealth of knowledge in both music and technology. Get to know the innovative masterminds that drive our company forward. \nOur platform is rooted in Sophia's life-long love of music and formal music education. Sophia, who holds a degree in music, recognizes the deep influence music can have on individuals. Her faith in the power of personalized music to improve the experience is what sparked our project. \nSophia and Marina make a formidable team, with Marina's software engineering experience complementing Sophia's musical expertise. Their shared appreciation for music is what brought them together and fueled Sophia's vision. Together, their  combination of skills has given birth to our revolutionary platform. Their joint venture blossomed while they were still starting college, a time during which they embarked on research projects that broadened their horizons and sparked creativity. Fueled by their unwavering commitment and distinct backgrounds, Sophia and Marina crafted our platform with a shared vision. They blaze the trail in personalized music, intent on delivering enjoyable musical experiences tailored to each individual. Their united pursuit of bringing music's transformative power to a worldwide audience is unprecedented."

function About() {
  const navItems = [
    { link: '/', text: 'Home' },
    { link: '/about', text: 'About' },
    { link: '/contact', text: 'Contact' },
  ];
  const mainStyle = {
    background: 'linear-gradient(360deg, #16182c 0%, #16182c 100%)',
    fontFamily: 'Inclusive Sans',
  };
  return (
    <div style={mainStyle}>
      <NavBarComponent items={navItems} height={5}  />
      <Article imageUrl={studioPic} title={"AIudio's Founding Journey"} text={textOfArticle} altText={"studioPic"} />
      <People items={[
        { imageUrl: "https://avatars.githubusercontent.com/u/119349066", altText: "Photo of Sophia with a cap", name:"Sophia Lima", 
        label: "Sophia occupies a central role in AIudio. With a lifelong dedication to music and her pursuit of a Computer Science degree, she stands at the intersection of these fields. Sophia merges her technical and musical expertise to elevate the work. Her understanding of music enhances the project while she oversees diverse aspects, guiding informed choices. Sophia's role spans project management, business strategy, technical contributions,  and creative direction, embodying her commitment to connecting music and technology." 
      },
      { imageUrl: "https://avatars.githubusercontent.com/u/68024498", altText: "Photo of Marina Smiling", name:"Marina Haru Marcoulakis", label: "Marina is currently serving as the Head of Engineering. Marina's role encompasses both technical and managerial aspects of the project, as she is currently pursuing a Computer Science degree. She has personally designed and built the AI model, selected and extracted essential data, and taken the lead in managing servers, app development, and software creation, also being a part of the project creative development and project management." }  
      ]} size={25} title={"Our Team"}/> 
    </div>
  );
}

export default About;