import '../styles/App.css';
import NavBarComponent from '../components/Navbar';
import People from "../components/People";
import ContactBox from '../components/ContactBox';
import SendMail from '../controllers/mail';


function About() {
  const navItems = [
    { link: '/', text: 'Home' },
    { link: '/about', text: 'About' },
    { link: '/contact', text: 'Contact' },
  ];
  const mainStyle = {
    background: 'linear-gradient(360deg, #16182c 0%, #16182c 100%)',
    fontFamily: 'Inclusive Sans',
  };
  return (
    <div style={mainStyle}>
      <NavBarComponent items={navItems} height={5}  />
      <ContactBox onSend={SendMail}/>
      <People items={[
        { imageUrl: "https://avatars.githubusercontent.com/u/119349066", altText: "Photo of Sophia with a cap", name:"Sophia Lima", label: "E-mail:", link: [{ url: "mailto:sophia@aiudio.com.br", text: "sophia@aiudio.com.br"}] },
        { imageUrl: "https://avatars.githubusercontent.com/u/68024498", altText: "Photo of Marina Smiling", name:"Marina Haru Marcoulakis", label: "E-mail:", link: [{ url: "mailto:marcoulakis@aiudio.com.br", text: "marcoulakis@aiudio.com.br"}]}  
      ]} size={25} hover={false}/> 
    </div>
  );
}

export default About;